<template>
  <div class="recentlyRead topmargin">
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">我的收藏</p>
        <a href="javaScript:" class="fr more">
          <img
            v-if="!isOpen"
            @click="chooseDelect"
            src="~img/account/icon_edit@2x.png"
            alt=""
          />
          <span
            v-show="isOpen && getDelete < historyList.length"
            @click="selectAll"
          >
            全選
          </span>
          <span
            v-show="isOpen && getDelete == historyList.length"
            @click="cancleAll"
          >
            取消全選
          </span>
        </a>
      </div>
    </div>
    <!-- 歷史記錄 -->
    <div v-if="historyList" class="ShelfRecommend normalBox">
      <ul
        class="bookmore"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="10"
      >
        <li v-for="item in historyList" class="clearfix" :key="item.id">
          <div class="fl moreimg" @click="selectItem(item.id)">
            <img v-lazy="item.iconUrlMedium" alt="" />
            <div class="isSelect">
              <img
                class="noselect"
                v-if="!item.select && isOpen"
                src="~img/account/read_option@2x.png"
                alt=""
              />
              <img
                class="select"
                v-if="item.select && isOpen"
                src="~img/account/read_option_sel@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="fl moreInfo">
            <a href="javaScript:" @click="selectItem(item.id)" class="name">{{
              item.name
            }}</a>
            <a
              href="javaScript:"
              @click="selectItem(item.id)"
              v-if="item.newChapter"
              class="chapterName"
              >最新：{{ item.newChapter.name }}</a
            >
            <div class="clearfix oldName">
              <span class="fl ellipsis" v-if="item.hisChapter"
                >讀至：{{ item.hisChapter.name }}</span
              >
              <router-link
                :to="'/book/' + item.id + '/' + item.hisChapter.id"
                class="fr"
                v-if="item.hisChapter"
                >繼續閱讀 ></router-link
              >
            </div>
          </div>
        </li>
      </ul>
      <div v-if="showMore" class="nonehint">
        <img src="~img/account/nonehint.png" alt="" />
      </div>
    </div>
    <noneHint
      v-if="historyList.length == 0"
      :img="hintimg"
      hint="主人還未收藏作品哦~~"
    ></noneHint>
    <div class="deletebox clearfix" v-if="isOpen">
      <p class="fl" @click="deleteItem">移除({{ getDelete }})</p>
      <a class="fr" href="javaScript:" @click="cancleDe">取消</a>
    </div>
  </div>
</template>

<script>
import { getshelf, deleteshelf } from "apiurl/user";
export default {
  data() {
    return {
      shelf1: [],
      historyList: [],
      isOpen: false,
      isAllSelect: true,
      deleteList: [],
      pageNum: 0,
      loading: false,
      hasNextPage: true,
      showMore: false,
      hintimg: require("../../static/nonebook.png")
    };
  },
  created() {
    this.$nextTick(() => {
      //this.gethistory(1, 10);
    });
  },
  computed: {
    getDelete() {
      let listNum = [];
      this.historyList.forEach(item => {
        if (item.select) {
          listNum.push(item.select);
        }
      });
      return listNum.length;
    }
  },
  methods: {
    // 歷史記錄
    async gethistory(pageNum, pageSize) {
      let res = await getshelf({ pageNum, pageSize });
      if (res.success) {
        res.data.items.forEach(item => {
          item.select = false;
        });
        this.historyList = res.data.items;
        if (this.historyList.length < 1) {
          this.showMore = false;
        }
        this.hasNextPage = res.data.hasNextPage;
      }
    },
    async loadMore() {
      if (this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await getshelf({ pageNum: this.pageNum, pageSize: 10 });
        if (res.success) {
          if (!this.isAllSelect) {
            res.data.items.forEach(item => {
              item.select = true;
            });
          } else {
            res.data.items.forEach(item => {
              item.select = false;
            });
          }
          this.hasNextPage = res.data.hasNextPage;
          this.historyList = [...this.historyList, ...res.data.items];
          if (this.historyList.length > 0) {
            if (this.historyList.length < 10) {
              this.showMore = true;
            }
          } else {
            this.showMore = false;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    },
    seeBack() {
      this.$router.go(-1);
    },
    // 開啓刪除
    chooseDelect() {
      if (this.historyList.length > 0) {
        this.isOpen = true;
      } else {
        this.Toast({
          message: "無作品可刪除~",
          duration: 2000,
          position: "top"
        });
      }
    },
    // 選擇元素
    selectItem(id) {
      if (this.isOpen) {
        this.historyList.forEach(item => {
          if (item.id == id) {
            item.select = !item.select;
          }
        });
      } else {
        this.$router.push(`/book/${id}`);
      }
    },
    // 取消刪除
    cancleDe() {
      this.isOpen = false;
      this.historyList.forEach(item => {
        item.select = false;
      });
    },
    // 刪除
    deleteItem() {
      this.historyList.forEach(item => {
        if (item.select) {
          this.deleteList.push(item.id);
        }
      });
      if (this.deleteList.length > 0) {
        let str = this.deleteList.join(",");
        deleteshelf({ ids: str }).then(res => {
          if (res.success) {
            this.isOpen = false;
            this.Toast({
              message: "刪除成功~",
              duration: 2000,
              position: "top"
            });
            this.gethistory();
          }
        });
      } else {
        this.Toast({
          message: "未選擇刪除作品~",
          duration: 2000,
          position: "top"
        });
      }
    },
    //  全選
    selectAll() {
      this.historyList.forEach(item => {
        item.select = true;
      });
      this.isAllSelect = false;
    },
    cancleAll() {
      this.historyList.forEach(item => {
        item.select = false;
      });
      this.isAllSelect = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
.topNavBox
    position fixed
    top 0px
    left 0
    width 100%
    z-index 10
.topbox
    height 100px
    line-height 100px
    padding 0 27px
    position relative
    background rgba(255, 255, 255, 1)
    border-bottom 1px solid #efefef
    margin-bottom 31px
    .more
        color #555555
        font-size 30px
        img
            width 36px
            height 36px
            margin-top 32px
    .back
        width 50px
        height 37px
        line-height 100px
        margin-top 13.5px
        img
            width 18px
            height 37px
    p
        position absolute
        left 0px
        top 0px
        bottom 0px
        right 0px
        margin auto
        text-align center
        font-size 34px
        color #333333
        width 70%
.bookmore
    li
        padding-bottom 30px
        border-bottom 1px solid #eee
        margin-bottom 30px
        .moreimg
            width 171px
            height 239px
            margin-right 27px
            background rgba(242, 242, 242, 1)
            box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.2)
            border-radius 8px
            position relative
            img
                width 100%
                border-radius 8px
            .isSelect
                position absolute
                top 7px
                left 5px
                img
                    width 24px
        .moreInfo
            width 500px
            a
                display block
            .name
                font-size 28px
                margin-bottom 18px
                margin-top 10px
            .chapterName
                color rgba(153, 153, 153, 1)
                line-height 33px
                font-size 24px
                padding-bottom 90px
            .oldName
                span
                    display inline-block
                    line-height 46px
                    width 65%
                a
                    padding 0 11px
                    height 46px
                    border-radius 8px
                    border 2px solid rgba(238, 238, 238, 1)
                    color #2189F2
                    font-size 20px
                    line-height 46px
                    text-align center
.nonehint
    margin 65px auto 30px
    width 209px
    img
        width 209px
.deletebox
    padding 0 24px
    width 100%
    box-sizing border-box
    height 95px
    background rgba(255, 255, 255, 1)
    box-shadow 0px -1px 16px 0px rgba(47, 50, 127, 0.11)
    line-height 95px
    position fixed
    bottom 0
    font-size 30px
    p
        color #FA5757
</style>
